import React from "react";
import Projects from "./Projects";

export default function Grid() {
  return (
    <div>
      <Projects />
    </div>
  );
}
